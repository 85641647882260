import qs from 'qs';
import { find } from 'lodash';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight, faSearch, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import { Link, RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import api, { ApiMeta } from '../../../api';
import { ApiHospital } from '../../../api/hospital';
import { ApiPage } from '../../../api/page';
import Container from '../../../modules/front/Container';
import Content from '../../../modules/front/Content';
import { Button, Checkbox, Input, Table } from '../../../RbKit';
import Loader from '../../../RbKit/elements/Loader';
import { useDebounceSearch } from '../../../RbKit/lib/hooks';
import styles from './styles.module.scss';
import { Helmet } from 'react-helmet';

interface SearchViewProps extends RouteComponentProps<{ slug?: string }> {
}

const SearchView: FC<SearchViewProps> = ({ match }): JSX.Element => {
    const { url } = match;
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ selectedHospitals, setSelectedHospitals ] = useState<ApiHospital[]>([]);
    const [ fetched, setFetched ] = useState<boolean>(false);
    const [ page, setPage ] = useState<ApiPage>();
    const [ query, setQuery ] = useState<string>('');
    const [ hospitals, setHospitals ] = useState<ApiHospital[]>([]);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ coords, setCoords ] = useState<any>();
    const [ provideLocation, setProvideLocation ] = useState<boolean|undefined>();
    const history = useHistory();
    const location = useLocation();

    const fetch = (q: string, p?: number, c?: any) => {
        setIsLoading(true);
        api.listHospitals({ query: q, page: p || 1, location: 1, coords: { ...(c || {})} }).then(({ data }) => {
            setHospitals((h) => (p || 1) === 1 ? data.data : [ ...h, ...data.data ]);
            setIsLoading(false);
            setMeta(data.meta);
        });
    }

    useEffect(() => {
        if (provideLocation) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((coords) => {
                    const c = {
                        lat: coords.coords.latitude,
                        lng: coords.coords.longitude,
                    }
                    history.push(`/zoeken?query=${query}&coords[0]=${c.lat}&coords[1]=${c.lng}`);
                });
            }
        } else if (provideLocation !== undefined) {
            history.push(`/zoeken?query=${query}`);
        }
    }, [provideLocation, query, history]);

    useEffect(() => {
        api.front.getPageBySlug(url.substring(1)).then(({ data }) => {
            setFetched(true);
            setPage(data);
        }).catch(() => {
            setFetched(true);
        });
    }, [url]);

    const debounceFetch = useDebounceSearch((q) => {
        const s: any = qs.parse(q);
        fetch(s.query, 1, s.coords);
    });

    useEffect(() => {
        const q = qs.parse(location.search.substring(1));
        
        if (q) {
            setQuery(q.query as string);
            setCoords(q.coords);
            if (q.coords) {
                setProvideLocation(true);
            }
            if (q.compare) {
                api.front.getHospital(parseInt(`${q.compare}`), new Date().getFullYear() - 2).then(({ data }) => {
                    setSelectedHospitals([data.hospital]);
                });
            }
        }
    }, [location]);

    useEffect(() => {
        debounceFetch(qs.stringify({ query, coords }));
    }, [query, coords, debounceFetch]);

    useEffect(() => {
        if (!fetched) return;
        const input = document.getElementById('search-input');
        if (input) input.focus();
    }, [fetched]);

    const toggleSelection = (state: boolean, hospital: ApiHospital): void => {
        const newSelection = [ ...selectedHospitals ];

        if (state) {
            newSelection.push(hospital);
            setSelectedHospitals(newSelection);
        } else {
            setSelectedHospitals(newSelection.filter((o) => o.id !== hospital.id));
        }
    }

    const toggleAll = (state: boolean): void => {
        let newSelection = [ ...selectedHospitals ];

        if (state) {
            hospitals.map((o) => {
                if (!newSelection.includes(o)) {
                    newSelection.push(o);
                }
                return o;
            });
        } else{
            newSelection = newSelection.filter((o) => !hospitals.map((p) => p.id).includes(o.id));
        }

        setSelectedHospitals(newSelection);
    }

    if (!fetched) {
        return <Loader />
    }

    return (<>
        {page && <Helmet>
            <title>{page.seoTitle || page.name}</title>
            <meta name="robots" content={page.seoNoIndex ? 'noindex,nofollow' : 'index,follow'} />
            <meta name="googlebot" content={`${page.seoNoIndex ? 'noindex,nofollow' : 'index,follow'}, max-snippet:-1, max-image-preview:large, max-video-preview:-1`} />
            <meta name="bingbot" content={`${page.seoNoIndex ? 'noindex,nofollow' : 'index,follow'}, max-snippet:-1, max-image-preview:large, max-video-preview:-1`} />
            <meta property="og:locale" content="nl_NL" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={page.seoTitle || page.name} />
            <meta property="og:description" content={page.seoDescription || ''} />
            <meta property="og:url" content={page.slug} />
            <meta property="og:site_name" content="Monitor Borstkanker" />
            {page.seoImage && <meta property="og:image" content={page.seoImage.src} />}
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>}
        <Content blocks={page?.content} settings={{}} />
        <Container small>
            <div className={styles.searchInput}>
                <Input
                    id="search-input"
                    placeholder="Zoeken op ziekenhuisnaam, postcode of locatie"
                    onChange={({ value }: any) => history.push(`/zoeken?${qs.stringify({ query: value, coords })}`)}
                    value={query}
                    className={styles.input}
                />
                <div className={styles.location}>
                    <Checkbox
                        label="Gebruik mijn locatie"
                        checked={coords !== undefined}
                        onChange={({ checked }) => setProvideLocation(checked)}
                    />
                    {provideLocation && !coords && <Loader />}
                </div>
                <FontAwesomeIcon
                    icon={query === '' ? faSearch : faTimes}
                    onClick={() => history.push('/zoeken?query=')}
                />
            </div>

            <div className={styles.hospitals}>
                <div className={styles.data}>
                    {isLoading && <Loader />}
                    <Table>
                        <thead>
                            <Table.Row>
                                <Table.HeaderCell collapsing>
                                    <Checkbox
                                        checked={selectedHospitals.filter((o) => hospitals.map((o) => o.id).includes(o.id)).length === hospitals.length}
                                        onChange={({ checked }: any) => toggleAll(checked)}
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Ziekenhuis
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Locatie
                                </Table.HeaderCell>
                                {hospitals[0]?.distance !== undefined && hospitals[0]?.distance !== null && (
                                    <Table.HeaderCell collapsing>
                                        Afstand
                                    </Table.HeaderCell>
                                )}
                                <Table.HeaderCell collapsing />
                            </Table.Row>
                        </thead>
                        <tbody>
                            {hospitals.map((hospital) => (
                                <Table.Row key={`hosp-${hospital.id}`}>
                                    <Table.Cell collapsing>
                                        <Checkbox
                                            checked={find(selectedHospitals, { id: hospital.id }) !== undefined}
                                            onChange={({ checked }: any) => toggleSelection(checked, hospital)}
                                            style={{ margin: 0 }}
                                        />
                                    </Table.Cell>
                                    <Table.Cell primary name="Ziekenhuis" onClick={() => history.push(`/${hospital.slug}`)}>
                                        {hospital.name}
                                    </Table.Cell>
                                    <Table.Cell name="Locatie" onClick={() => history.push(`/${hospital.slug}`)}>
                                        {hospital.city}
                                    </Table.Cell>
                                    {hospital.distance !== undefined && hospital.distance !== null && (
                                        <Table.Cell collapsing>
                                            {hospital.distance}km
                                        </Table.Cell>
                                    )}
                                    <Table.Cell collapsing>
                                        <Button
                                            icon={faChevronRight}
                                            trigger
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </tbody>
                    </Table>
                    {meta && meta.total > 15 && <Table.Pagination
                        infinite
                        isLoading={isLoading}
                        meta={meta}
                        onChange={(page: number) => fetch(query, page)}
                    />}
                </div>
                {selectedHospitals.length > 0 && (
                    <div className={styles.selected}>
                        <Table style={{ marginBottom: '1rem' }}>
                            <tbody>
                                {selectedHospitals.map((hospital) => (
                                    <Table.Row key={`hosp-${hospital.id}`}>
                                        <Table.Cell primary name="Ziekenhuis">
                                            {hospital.name}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Button
                                                onClick={() => toggleSelection(false, hospital)}
                                                icon={faTrashAlt}
                                                trigger
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </tbody>
                        </Table>
                        <p>
                            {selectedHospitals.length > 1 ? (
                                <Link
                                    className="button"
                                    to={`/vergelijker?hospitals=${selectedHospitals.map((o) => o.id).join(',')}`}
                                >
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                    />
                                    Vergelijken
                                </Link>
                            ) : (
                                <span>Selecteer nog één ander ziekenhuis om te vergelijken</span>
                            )}
                        </p>
                    </div>
                )}
            </div>
        </Container>
    </>);
}

export default SearchView;
