import { faColumns } from '@fortawesome/pro-light-svg-icons';
import React, { FC } from 'react';
import parse from 'html-react-parser';
import { blockComponents } from '../';
import { getValue } from '../../../../lib/block';
import { BlockProps, Segment } from '../../../../RbKit';
import textStyles from '../Text/styles.module.scss';
import styles from './styles.module.scss';

const Block: FC<BlockProps> = ({ data, front }): JSX.Element => {
    const Container = front ? 'div' : Segment; 
    const Image = blockComponents.image;
    let width = getValue(data, 'width');

    if (!width || width === '' || window.innerWidth < 768) {
        width = '100%';
    } else if (!width.includes('%') && !width.includes('px')) {
        width = `${width}%`;
    }

    return (
        <Container
            className={`${styles.segment} ${getValue(data, 'position') === 'left' ? '' : styles.right} ${textStyles.container} ${front ? '' : textStyles.cms}`}
            data-aos={front ? 'zoom-in-up' : false}
            style={{ alignItems: 'flex-start' }}
        >
            <div className={styles.thumb} style={{ width }}>
                <Image data={data} front={front} />
            </div>
            <div className={styles.content}>
                {parse(getValue(data, 'content') || '')}
            </div>
        </Container>
    );
}

const data = {
    Block,
    icon: faColumns,
    getData: () => ({
        block: 'imagetext',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }, {
            id: 'content',
            type: 'wysiwyg',
            label: 'Content',
            value: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec hendrerit augue nisl, vel dictum quam efficitur in. Vivamus aliquet lorem in turpis pretium, ac consectetur massa molestie. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec nisl nisi, vehicula at risus et, convallis convallis lacus. Mauris ut pellentesque quam. Cras eros est, fermentum non lorem id, porta ultricies enim. Duis id sapien et erat dictum vulputate congue quis erat. Quisque id finibus mi.</p>',
        }, {
            id: 'image',
            type: 'image',
            label: 'Image',
            value: '',
        }, {
            id: 'zoom',
            type: 'checkbox',
            label: 'Enable zoom',
            value: false,
        }, {
            id: 'alt',
            type: 'text',
            label: 'Alt text',
            value: '',
        }, {
            id: 'position',
            type: 'dropdown',
            label: 'Image position',
            value: 'left',
            props: {
                options: [{
                    text: 'Left',
                    value: 'left',
                }, {
                    text: 'Right',
                    value: 'right',
                }]
            }
        }, {
            id: 'caption',
            type: 'text',
            label: 'Caption',
            value: '',
        }, {
            id: 'link',
            type: 'text',
            label: 'Image link',
            value: '',
        }, {
            id: 'width',
            type: 'text',
            label: 'Image width (px or %)',
            value: '33%',
        }],
    })
};

export default data;
