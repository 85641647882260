import { range } from 'lodash';
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Checkbox, Form, Grid, PageHeader, Segment, toast } from '../../../RbKit';
import api from '../../../api';
import { ApiIndicator } from '../../../api/indicator';
import ChangeHandler from '../../../components/ChangeHandler';

interface IndicatorEditProps extends RouteComponentProps<{ id?: string }> {
}

const IndicatorEditView: FC<IndicatorEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ indicator, setIndicator ] = useState<Partial<ApiIndicator>>({ type: 'Percentage' });
    const [ changesMade, setChangesMade ] = useState<boolean>(false);
    const [ parents, setParents ] = useState<ApiIndicator[]>([]);
    const years = range(2020, new Date().getFullYear() + 1);

    const fetch = useCallback(() => {
        setChangesMade(false);
        if (id) {
            setIsLoading(true);
            api.getIndicator(parseInt(id)).then(({ data }) => {
                setIndicator(data);
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        api.listIndicators({ limit: 999 }).then(({ data }) => setParents(data.data));

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setChangesMade(true);
        setIndicator({
            ...indicator,
            [name]: value,
        });
    }

    const save = (e?: FormEvent): void => {
        if (e) e.preventDefault();
        setIsLoading(true);

        api.putIndicator(indicator).then(() => {
            setIsLoading(false);
            setChangesMade(false);
            toast('Indicator succesvol opgeslagen');
            history.push('/admin/indicators');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Er ging iets mis', 'error')
        });
    }

    return (<>
        <ChangeHandler
            changesMade={changesMade}
            onCancel={() => fetch()}
            onSave={() => save()}
        />
        <PageHeader
            breadcrumb={{
                '/admin/indicators': 'Indicator',
                [`/admin/indicators/${id ? `${id}/edit` : 'create'}`]: id ? indicator.name || 'Nieuw' : 'Nieuw',
            }}
            title={`${id ? 'Wijzig' : 'Nieuw'} ziekenhuis`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Grid.Row>
                <Grid.Column md={9}>
                    <Segment isLoading={isLoading}>
                        <Form.Group>
                            <Checkbox
                                checked={indicator.isActive}
                                label="Actief (tonen op website)"
                                onChange={({ checked }: any) => handleInput({ name: 'isActive', value: checked })}
                            />
                        </Form.Group>
                        <Form.Dropdown
                            label="Hoofdindicator"
                            name="parentId"
                            options={[{
                                text: 'Geen',
                                value: '',
                            }, ...parents.map((parent) => ({
                                text: parent.name,
                                value: parent.id,
                            }))]}
                            onChange={handleInput}
                            value={indicator.parentId}
                        />
                        <Form.Input
                            error={errors.code}
                            label="Code"
                            name="code"
                            onChange={handleInput}
                            required
                            value={indicator.code || ''}
                        />
                        <Grid.Row>
                            <Grid.Column md={9}>
                                <Form.Input
                                    error={errors.name}
                                    label="Naam"
                                    name="name"
                                    onChange={handleInput}
                                    required
                                    value={indicator.name || ''}
                                />
                            </Grid.Column>
                            <Grid.Column md={3}>
                                <Form.Dropdown
                                    error={errors.type}
                                    label="Type"
                                    name="type"
                                    options={[{
                                        text: 'Aantal',
                                        value: 'Aantal',
                                    }, {
                                        text: 'Percentage',
                                        value: 'Percentage',
                                    }, {
                                        text: 'Ja/Nee',
                                        value: 'Boolean',
                                    }]}
                                    onChange={handleInput}
                                    required
                                    value={indicator.type}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Form.Textarea
                            label="Omschrijving"
                            name="description"
                            onChange={handleInput}
                            rows={5}
                            value={indicator.description || ''}
                        />
                    </Segment>
                </Grid.Column>
                <Grid.Column md={3}>
                    <Segment>
                        <Form.Dropdown
                            label="Beschikbare jaren"
                            multiple
                            name="years"
                            options={years.map((y) => ({
                                text: `${y}`,
                                value: y,
                            }))}
                            onChange={handleInput}
                            value={indicator.years || []}
                        />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Form>
    </>);
}

export default IndicatorEditView;
