import { faPlus, faPencil, faTrashAlt, faRetweet, faUpload, faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import React, { createRef, FC, RefObject, useCallback, useEffect, useState } from 'react';
import { range, sortBy } from 'lodash';
import { Link } from 'react-router-dom';
import { Button, Confirm, Form, Menu, Message, PageHeader, Segment, Table, toast, useTableSort } from '../../../RbKit';
import api, { ApiMeta } from '../../../api';
import { ApiIndicator } from '../../../api/indicator';
import SortMode from './SortMode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IndicatorListView: FC = (): JSX.Element => {
    const fileInputRef: RefObject<HTMLInputElement> = createRef();
    const [ errors, setErrors ] = useState<any>();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ orderBy, order, setSorting ] = useTableSort('firstName');
    const [ indicators, setIndicators ] = useState<ApiIndicator[]>([]);
    const [ sortMode, setSortMode ] = useState<boolean>(false);
    const [ year, setYear ] = useState<number>(new Date().getFullYear() - 1);
    const years = range(2020, new Date().getFullYear());

    const fetch = useCallback((query?: string, page: number = 1): void => {
        setIsLoading(true);
        api.listIndicators({ query, page, orderBy, order, year }).then(({ data }) => {
            setMeta(data.meta);
            setIndicators(data.data);
            setIsLoading(false);
        });
    }, [orderBy, order, year]);
    useEffect(() => fetch(), [orderBy, order, fetch, year]);

    const deleteIndicator = (indicatorId: number | string): void => {
        api.deleteIndicator(indicatorId).then(() => {
            fetch();
            toast('Indicator succesvol verwijderd');
        });
    }

    const handleFileUpload = (e: any): void => {
        setErrors(undefined);

        api.importIndicators(e.target.files[0], year).then((res) => {
            toast('Import succesvol geüpload, deze zal op de achtergrond draaien.');
        }).catch(({ response }) => {
            if (response.data.errors.file) {
                toast('Ongeldig format, upload CSV of Excel', 'error');
            } else {
                toast('Fouten gevonden in bestand', 'error');
                setErrors(response.data.errors);
            }
        });
    }

    return (<>
        <PageHeader
            title="Indicatoren"
            breadcrumb={{'/admin/indicators': 'Overzicht'}}
        >
            <Button
                href="/admin/indicators/create"
                icon={faPlus}
                padding="compact"
                primary
            />
            <Button
                icon={faRetweet}
                label="Sorteren"
                onClick={() => setSortMode(true)}
            />
            <Button
                icon={faUpload}
                label="Importeren"
                onClick={() => fileInputRef.current?.click()}
            />
            <input
                accept=".csv, .xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleFileUpload}
                ref={fileInputRef}
                type="file"
                style={{ display: 'none' }}
            />
        </PageHeader>

        {errors ? Object.keys(errors).map((line: any, index) => (
            <Message
                key={`err-${index}`}
                error
                title={`Foutmelding op regel ${line}`}
            >
                <ul>
                    {errors[line].map((o: string, i: number) => <li key={`err-${index}-${i}`}>{o}</li>)}
                </ul>
            </Message>
        )) : sortMode ? (
            <SortMode
                onCancel={() => setSortMode(false)}
                onUpdate={() => {
                    setSortMode(false);
                    toast('Volgorde succesvol opgeslagen');
                    fetch();
                }}
                items={sortBy(indicators, 'position')}
            />
        ) : (<>
            {/* <div style={{ maxWidth: 160, zIndex: 9999, position: 'relative' }}>
                <Form.Dropdown
                    label="Jaar"
                    onChange={({ value }) => setYear(value)}
                    options={years.map((y) => ({
                        text: `${y}`,
                        value: y,
                    }))}
                    value={year}
                />
            </div> */}
            <Segment isLoading={isLoading}>
                <Table.Actions
                    onSearch={fetch}
                />
                <Table fluid>
                    <thead>
                        <Table.Row>
                            <Table.HeaderCell
                                align="center"
                                collapsing
                            >
                                Actief
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                collapsing
                                sorted={[orderBy, 'code']}
                                order={order}
                                onSort={setSorting}
                            >
                                Code
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={[orderBy, 'name']}
                                order={order}
                                onSort={setSorting}
                            >
                                Naam
                            </Table.HeaderCell>
                            <Table.HeaderCell collapsing />
                        </Table.Row>
                    </thead>
                    <tbody>
                        {indicators.length > 0 ? indicators.map((indicator) => (
                            <Table.Row key={`row-${indicator.id}`}>
                                <Table.Cell
                                    align="center"
                                    collapsing
                                    icon={<FontAwesomeIcon
                                        icon={indicator.isActive ? faCheck : faTimes}
                                        className={indicator.isActive ? 'success' : 'error'}
                                    />}
                                />
                                <Table.Cell name="Code" collapsing>
                                    {indicator.code}
                                </Table.Cell>
                                <Table.Cell primary name="Naam">
                                    <Link to={`/admin/indicators/${indicator.id}/edit`}>
                                        {indicator.name}
                                    </Link>
                                </Table.Cell>
                                <Table.Cell collapsing actions>
                                    <Menu dropdown>
                                        <Menu.Item
                                            icon={faPencil}
                                            content="Wijzigen"
                                            href={`/admin/indicators/${indicator.id}/edit`}
                                        />
                                        <Menu.Divider />
                                        <Confirm
                                            content="Weet je zeker dat je deze indicator wilt verwijderen? Gekoppelde informatie zal ook worden verwijderd."
                                            onConfirm={() => deleteIndicator(indicator.id)}
                                            trigger={<Menu.Item
                                                icon={faTrashAlt}
                                                content="Verwijderen"
                                            />}
                                        />
                                    </Menu>
                                </Table.Cell>
                            </Table.Row>
                        )) : (
                            <Table.Row noResults />
                        )}
                    </tbody>
                </Table>
            </Segment>
            {meta && <Table.Pagination
                meta={meta}
                onChange={(page: number) => fetch('', page)}
            />}
        </>)}
    </>);
}

export default IndicatorListView;
