import React, { FC, useEffect, useRef, useState } from 'react';

interface MapProps {
    coords: {
        lat: number,
        lng: number,
    },
}

const Map: FC<MapProps> = ({ coords }): JSX.Element => {
    const mapContainer = useRef<any>(null);
    const map = useRef<any>(null);
    const [ lat, setLat ] = useState(coords.lat);
    const [ lng, setLng ] = useState(coords.lng);
    const [ zoom, setZoom ] = useState(13);

    useEffect(() => {
        if (map.current) return;
        (window as any).mapboxgl.accessToken = process.env.REACT_APP_MAPS_KEY || '';
        map.current = new (window as any).mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [ lng, lat ],
            zoom: zoom
        });
    });

    useEffect(() => {
        if (!map.current) return;
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
    });

    return (
        <div ref={mapContainer} style={{ height: 250 }} />
    );
}

export default Map;
