import qs from 'qs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Container from '../../../modules/front/Container';
import { ApiHospital } from '../../../api/hospital';
import styles from './styles.module.scss';
import api from '../../../api';
import { ApiIndicator } from '../../../api/indicator';
import { Modal, Table } from '../../../RbKit';
import { number } from '../../../lib/util';
import { Link, useLocation } from 'react-router-dom';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

interface CompareViewProps {
}

const CompareView: FC<CompareViewProps> = (): JSX.Element => {
    const [ info, setInfo ] = useState<string | boolean>(false);
    const [ hospitals, setHospitals ] = useState<ApiHospital[]>([]);
    const [ indicators, setIndicators ] = useState<ApiIndicator[]>([]);
    const location = useLocation();

    useEffect(() => {
        const query = qs.parse(location.search.substring(1));
        if (query && query.hospitals && query.hospitals !== '') {
            api.front.getHospitals(query.hospitals as string).then(({ data }) => {
                setHospitals(data);
            });
        }
    }, [location]);

    useEffect(() => {
        api.front.getIndicators().then(({ data }) => {
            setIndicators(data);
        });
    }, []);

    return (<>
        <Helmet>
            <title>Vergelijker</title>
            <meta name="robots" content="index,follow" />
            <meta name="googlebot" content="index,follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
            <meta name="bingbot" content="index,follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
            <meta property="og:locale" content="nl_NL" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Vergelijker" />
            <meta property="og:url" content="/vergelijker" />
            <meta property="og:site_name" content="Monitor Borstkanker" />
        </Helmet>
        <Container small>
            <div className={styles.location}>
                {hospitals.map((hospital, index) => (
                    <div key={`info-${hospital.id}`}>
                        <div className={styles.address}>
                            <span className={styles.badge}>{index + 1}</span>
                            <b>{hospital.name}</b><br />
                            {hospital.street}<br />
                            {hospital.zipcode} {hospital.city}<br />
                            <b>T:</b> {hospital.phone}<br />
                            <b>W:</b> <a href={hospital.url} target="_blank" rel="noopener noreferrer">{hospital.url}</a><br /><br />
                        </div>
                    </div>
                ))}
            </div>

            <div className={styles.indicators}>
                {indicators.filter((o, i) => i === 0).map((parent) => {
                    const lvl2 = indicators.filter((o) => !o.parentId);

                    return (
                        <div
                            key={parent.id}
                        >
                            <h3 className={styles.indicator}>Indicatoren</h3>
                            {lvl2.length > 0 ? (<>
                                {/* <h3 className={styles.indicator}>
                                    {parent.name}
                                    {parent.description && (
                                        <FontAwesomeIcon
                                            icon={faInfoCircle}
                                            className={styles.icon}
                                            onClick={() => setInfo(parent.description)}
                                            style={{ position: 'relative', marginLeft: '.5rem', top: -2 }}
                                        />
                                    )}
                                </h3> */}
                                <Table key={`tab-${parent.id}`} responsive={false}>
                                    <thead>
                                        <Table.Row>
                                            <Table.HeaderCell collapsing />
                                            <Table.HeaderCell />
                                            {hospitals.map((h, index) => (
                                                <Table.HeaderCell collapsing align="right">
                                                    <span className={styles.badge}>{index + 1}</span>
                                                </Table.HeaderCell>
                                            ))}
                                        </Table.Row>
                                    </thead>
                                    <tbody>
                                        {lvl2.map((o) => {
                                            const lvl3 = indicators.filter((i) => i.parentId === o.id);

                                            return (<>
                                                <Table.Row key={o.id}>
                                                    <Table.Cell
                                                        collapsing
                                                        icon={o.description ? <FontAwesomeIcon
                                                            icon={faInfoCircle}
                                                            className={styles.icon}
                                                            onClick={() => setInfo(o.description)}
                                                        /> : undefined}
                                                    />
                                                    <Table.Cell>
                                                        <b>{o.name}</b>
                                                    </Table.Cell>
                                                    {hospitals.map((h) => {
                                                        const value = find(h.indicators, { id: o.id });
                                                        return <Table.Cell
                                                            key={`l2-${h.id}`}
                                                            collapsing
                                                            align="right"
                                                        >
                                                            {value?.type === 'Boolean'
                                                                ? (value?.value === 1 ? 'Ja' : 'Nee')
                                                                : number(value?.value)
                                                            }
                                                            {value?.type === 'Percentage' && '%'}
                                                        </Table.Cell>
                                                    })}
                                                </Table.Row>
                                                {lvl3.map((indicator) => (
                                                    <Table.Row key={indicator.id}>
                                                        <Table.Cell
                                                            collapsing
                                                            icon={indicator.description ? <FontAwesomeIcon
                                                                icon={faInfoCircle}
                                                                className={styles.icon}
                                                                onClick={() => setInfo(indicator.description)}
                                                            /> : undefined}
                                                        />
                                                        <Table.Cell>{indicator.name}</Table.Cell>
                                                        {hospitals.map((h) => {
                                                            const v = find(h.indicators, { id: indicator.id });
                                                            return <Table.Cell
                                                                key={`l3-${h.id}`}
                                                                collapsing
                                                                align="right"
                                                            >
                                                                {v?.type === 'Boolean'
                                                                    ? (v?.value === 1 ? 'Ja' : 'Nee')
                                                                    : number(v?.value)
                                                                }
                                                                {v?.type === 'Percentage' && '%'}
                                                            </Table.Cell>
                                                        })}
                                                    </Table.Row>
                                                ))}
                                            </>);
                                        })}
                                    </tbody>
                                </Table>
                            </>) : (
                                <Table key={parent.id} responsive={false}>
                                    <thead>
                                        <Table.Row>
                                            <Table.HeaderCell collapsing />
                                            <Table.HeaderCell />
                                            {hospitals.map((h, index) => (
                                                <Table.HeaderCell collapsing align="right">
                                                    <span className={styles.badge}>{index + 1}</span>
                                                </Table.HeaderCell>
                                            ))}
                                        </Table.Row>
                                    </thead>
                                    <tbody>
                                        <Table.Row>
                                            <Table.Cell
                                                collapsing
                                                icon={parent.description ? <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                    className={styles.icon}
                                                    onClick={() => setInfo(parent.description)}
                                                /> : undefined}
                                            />
                                            <Table.Cell>
                                                <b>{parent.name}</b>
                                            </Table.Cell>
                                            {hospitals.map((h) => {
                                                const value = find(h.indicators, { id: parent.id });
                                                return <Table.Cell
                                                    key={`l2-${h.id}`}
                                                    collapsing
                                                    align="right"
                                                >
                                                    {value?.type === 'Boolean'
                                                        ? (value?.value === 1 ? 'Ja' : 'Nee')
                                                        : number(value?.value)
                                                    }
                                                    {value?.type === 'Percentage' && '%'}
                                                </Table.Cell>
                                            })}
                                        </Table.Row>
                                    </tbody>
                                </Table>
                            )}
                        </div>
                    );
                })}
            </div>
            <Link to="/zoeken">
                Terug naar zoeken
            </Link>{' | '}
            <Link to="/goede-informatie-helpt">
                Uitgebreide uitleg over indicatoren
            </Link>
        </Container>
        <Modal
            open={info !== false}
            size="medium"
            header="Informatie"
            onClose={() => setInfo(false)}
            footer={(
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button
                        className="button"
                        onClick={() => setInfo(false)}
                    >Sluiten</button>
                </div>
            )}
        >
            <p style={{ margin: 0 }}>
                {info}
            </p>
        </Modal>
    </>);
}

export default CompareView;
