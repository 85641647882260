import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from '.';

export interface ApiDocument {
    id: number,
    extension: string,
    name: string,
    src: string,
}

const rest = {
    delete: (documentId: number | string): AxiosPromise => {
        return axios.delete(`documents/${documentId}`);
    },
    get: (documentId: number): AxiosPromise<ApiDocument> => {
        return axios.get(`documents/${documentId}`);
    },
    list: (query?: { [key: string]: any }): AxiosPromise<ApiPaginatedResponse<ApiDocument[]>> => {
        return axios.get(`documents?${qs.stringify(query)}`);
    },
    upload: (
        file: any,
        onUploadProgress?: (progressEvent: any) => void,
        forObject?: string,
        forObjectId?: number,
    ): AxiosPromise => {
        const fd = new FormData();
        fd.append('file', file);
        if (forObject) fd.append('forObject', forObject);
        if (forObjectId) fd.append('forObject_id', `${forObjectId}`);

        return axios.post('documents', fd, {
            onUploadProgress,
        });
    }
}

export default rest;
