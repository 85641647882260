import { faChevronRight, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import api from '../../../api';
import { ApiNavigation } from '../../../api/page';
import { Input } from '../../../RbKit';
import Container from '../Container';
import styles from './styles.module.scss';
import searchStyles from '../../../views/front/SearchView/styles.module.scss';

const TopBar: FC = (): JSX.Element => {
    const [ menuItems, setMenuItems ] = useState<ApiNavigation[]>([]);
    const [ menuOpen, setMenuOpen ] = useState<boolean>(false);
    const [ query, setQuery ] = useState<string>('');
    const history = useHistory();

    useEffect(() => {
        if (menuOpen && menuItems.length <= 0) {
            api.front.listNavigation().then(({ data }) => setMenuItems(data));
        }
        if (!menuOpen) {
            const main = document.getElementById('main');
            if (!main) return;
            main.scrollTo({ top: 0 });
        }
    }, [menuOpen, menuItems]);

    useEffect(() => {
        document.body.classList.toggle('menu-open', menuOpen);
    }, [menuOpen]);

    return (<>
        <div className={styles.container}>
            <Container className={styles.innerContainer}>
                <Link className={styles.logo} to="/">
                    <img src="/logo.svg" alt="Monitor borstkanker" />
                </Link>
                <div className={styles.right}>
                    <Link className={styles.search} to="/zoeken">
                        <FontAwesomeIcon
                            icon={faSearch}
                        />
                    </Link>
                    <a
                        className={styles.support}
                        href="https://www.borstkanker.nl/steun-ons"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FontAwesomeIcon icon={faChevronRight} />
                        Steun ons
                    </a>

                    <div
                        className={`${styles.bars} ${menuOpen && styles.open}`}
                        onClick={() => setMenuOpen(!menuOpen)}
                    >
                        <div>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </Container>
            <div className={`${styles.menuOverlay} ${menuOpen ? styles.open : ''}`}>
                <div className={styles.inner}>
                    <div className={styles.navigation}>
                        {menuItems.map((item, i) => (
                            <Link
                                key={`nav-${i}`}
                                to={item.slug}
                                onClick={() => setMenuOpen(false)}
                            >
                                {item.name}
                            </Link>
                        ))}
                    </div>
                    <div className={styles.search}>
                        <div className={searchStyles.searchInput}>
                            <Input
                                id="search-input"
                                placeholder="Zoeken op ziekenhuisnaam, postcode of locatie"
                                onChange={({ value }: any) => setQuery(value)}
                                value={query}
                                className={searchStyles.input}
                            />
                            <FontAwesomeIcon
                                icon={faSearch}
                                onClick={() => { setMenuOpen(false); history.push(`/zoeken?query=${query}`); }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.push} />
    </>);
}

export default TopBar;
