import React, { FC } from 'react';
import api from '../../api';
import { useProfile } from '../../lib/auth';
import styles from './styles.module.scss';

const getShortName = (name: string): string => {
    const parts = name.split(' ');
    return `${parts[0][0]}${parts[parts.length - 1][0]}`;
}

const TopBar: FC = (): JSX.Element => {
    const profile = useProfile();

    return (<>
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <div className={styles.title}>
                    <h1>{process.env.REACT_APP_APPLICATION_NAME}</h1>
                </div>
                {profile && (
                    <div className={styles.account}>
                        <div
                            className={styles.avatar}
                            onClick={() => api.logout()}
                        >
                            {getShortName(`${profile.first_name} ${profile.last_name}`)}
                        </div>
                    </div>
                )}
                <img alt="Monitor Borstkankerzorg" className={styles.logo} src="/logo.png" />
            </div>
        </div>
    </>);
}

export default TopBar;
