import { faImage, faSearchPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { getValue } from '../../../../lib/block';
import { BlockProps, Segment } from '../../../../RbKit';
import ImageThumb from '../../../../components/ImageThumb';
import { fitImage } from '../../../../lib/image';
import styles from './styles.module.scss';
import Container from '../../../front/Container';

const Block: FC<BlockProps> = ({ data, front }): JSX.Element | null => {
    const image = getValue(data, 'image');
    const mobile = getValue(data, 'mobileImage');

    if (front) {
        if (!image || !image.src) return null;
        const align = getValue(data, 'align');
        const caption = getValue(data, 'caption');
        const zoom = getValue(data, 'zoom');
        const link = getValue(data, 'link');
        const fullImage = fitImage(image.src, 1140);
        let width = getValue(data, 'width');
        if (!width || width === '') {
            width = '100%';
        } else if (!width.includes('%') && !width.includes('px')) {
            width = `${width}%`;
        }

        let Elem = 'div';
        let elemProps: any = {};

        if (zoom === true) {
            Elem = 'a';
            elemProps.href = image.src;
            elemProps.onClick = (e: any) => {
                e.preventDefault();
            }
        } else if (link && link !== '') {
            Elem = 'a';
            elemProps.href = link;
            if (!link.includes('borstkankermonitor')) {
                elemProps.target = "_blank";
            }
        }

        return (
            <Container style={{ display: 'flex', justifyContent: align || 'flex-start' }}>
                <Elem
                    className={`${styles.image} ${zoom === true ? styles.hasZoom : ''}`}
                    data-aos="zoom-in-up"
                    {...elemProps}
                >
                    <picture>
                        {mobile && (<>
                            <source media="(max-width: 767px)" srcSet={fitImage(mobile.src, 1140)} />
                            <source media="(min-width: 768px)" srcSet={fullImage} />
                        </>)}
                        <img src={fullImage} alt={getValue(data, 'alt')} style={{ width }} />
                    </picture>
                    {caption && caption !== '' && <em className={styles.imageCaption}>{caption}</em>}
                    {zoom === true && (
                        <div className={styles.imageZoom}>
                            <FontAwesomeIcon icon={faSearchPlus} />
                        </div>
                    )}
                </Elem>
            </Container>
        )
    }

    return (
        <Segment>
            <ImageThumb
                square
                image={getValue(data, 'image')}
            />
            {mobile && (
                <ImageThumb
                    square
                    image={mobile}
                />
            )}
        </Segment>
    );
}

const data = {
    Block,
    icon: faImage,
    getData: () => ({
        block: 'image',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }, {
            id: 'image',
            type: 'image',
            label: 'Image',
            value: '',
        }, {
            id: 'mobileImage',
            type: 'image',
            label: 'Mobile image',
            value: '',
        }, {
            id: 'zoom',
            type: 'checkbox',
            label: 'Enable zoom',
            value: false,
        }, {
            id: 'alt',
            type: 'text',
            label: 'Alt text',
            value: '',
        }, {
            id: 'caption',
            type: 'text',
            label: 'Caption',
            value: '',
        }, {
            id: 'link',
            type: 'text',
            label: 'Link',
            value: '',
        }, {
            id: 'width',
            type: 'text',
            label: 'Image width (px or %), empty for default width',
            value: '',
        }, {
            id: 'align',
            type: 'dropdown',
            label: 'Uitlijning',
            value: 'flex-start',
            props: {
                options: [{
                    text: 'Links',
                    value: 'flex-start',
                }, {
                    text: 'Midden',
                    value: 'center',
                }, {
                    text: 'Rechts',
                    value: 'flex-end',
                }]
            }
        }],
    })
};

export default data;
