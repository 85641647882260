import React, { FC, useState } from 'react';
import Dropdown, { DropdownProps } from '../../elements/Dropdown';
import styles from './styles.module.scss';

interface FormDropdownProps extends DropdownProps {
    info?: string,
    label?: string,
    required?: boolean,
}

const FormDropdown: FC<FormDropdownProps> = ({ info, label, required, ...props }): JSX.Element => {
    const [ focus, setFocus ] = useState<boolean>(false);

    return (
        <div
            className={[
                styles.group,
                props.value || focus ? styles.focus : undefined,
                props.error && styles.hasError,
            ].join(' ')}
            style={focus ? { zIndex: 3 } : {}}
        >
            {label && <label className={styles.label}>
                {label}
                {required && <span>*</span>}
            </label>}
            <Dropdown
                onBlur={() => setFocus(false)}
                onFocus={() => setFocus(true)}
                {...props}
            />
            <p className={styles.info}>{typeof props.error === 'string' ? props.error : info}</p>
        </div>
    )
}

export default FormDropdown;
