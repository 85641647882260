import { faGlobe } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { getValue } from '../../../../lib/block';
import { BlockProps } from '../../../../RbKit';
import styles from './styles.module.scss';

const Block: FC<BlockProps> = ({ data, front }): JSX.Element => {
    return front ? (
        <iframe className={styles.clinical} src={getValue(data, 'url')} />
    ) : (
        <div className={styles.placeholder}>
            <FontAwesomeIcon icon={faGlobe} />
        </div>
    );
}

const data = {
    Block,
    icon: faGlobe,
    getData: () => ({
        block: 'iframe',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Ankerlink',
            value: '',
        }, {
            id: 'url',
            type: 'text',
            label: 'URL',
            value: '',
        }],
    })
};

export default data;
