import { sortBy } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { request } from '../../../api';
import { ApiIndicator } from '../../../api/indicator';
import { Segment, Button } from '../../../RbKit';
import SortableList from '../../../components/SortableList';

interface SortModeProps {
    items: ApiIndicator[],
    onCancel: () => void,
    onUpdate: (reordered?: ApiIndicator[]) => void,
}

const SortMode: FC<SortModeProps> = ({ items, onCancel, onUpdate }): JSX.Element => {
    const [ sorted, setSorted ] = useState<ApiIndicator[]>(items);
    useEffect(() => setSorted(items), [items]);

    const handleSort = (newOrder: ApiIndicator[]): void => {
        const newItems = [ ...sorted || [] ];

        newOrder.forEach((o, i) => {
            const index = newItems.findIndex((n) => n.id === o.id);
            newItems[index].position = i;
        });
        setSorted(sortBy(newItems, (o) => o.position));
    }

    const handleSave = (): void => {
        request.post('admin/indicators/sort', { indicators: sorted.map(o => o.id) }).then(() => {
            onUpdate(sorted);
        });
    }

    return (<>
        <div style={{ marginBottom: '1rem' }}>
            <Button
                label="Opslaan"
                onClick={() => handleSave()}
                primary
            />
            <Button
                label="Annuleren"
                onClick={onCancel}
            />
        </div>
        <SortableList
            items={sorted.filter((o) => !o.parentId)}
            onUpdate={(newItems) => handleSort(newItems)}
            renderListItem={(item) => {
                return (
                    <div style={{ marginBottom: 16 }}>
                        <Segment padding="dense" style={{ marginBottom: 6 }}>
                            {item.name}
                        </Segment>
                        <SortableList
                            items={sorted.filter((o) => o.parentId === item.id)}
                            onUpdate={(newItems) => handleSort(newItems)}
                            renderListItem={(item) => {
                                return (
                                    <Segment padding="dense" style={{ marginLeft: 24 }}>
                                        {item.name}
                                    </Segment>
                                );
                            }}
                        />
                    </div>
                );
            }}
        />
    </>);
}

export default SortMode;
