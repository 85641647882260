import React, { FC, HTMLAttributes } from 'react';
import styles from './styles.module.scss';

interface ProgressProps extends HTMLAttributes<HTMLDivElement> {
    progress: number,
}

const Progress: FC<ProgressProps> = ({ className, progress, ...props }) => {
    return (
        <div className={styles.base} {...props}>
            <div style={{ width: `${progress}%` }} />
        </div>
    );
}

export default Progress;
