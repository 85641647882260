import axios from 'axios';
import auth from './auth';
import document from './document';
import hospital from './hospital';
import image, { ApiImage } from './image';
import indicator from './indicator';
import page from './page';
import user from './user';
import tag from './tag';

export const getToken = () => {
    if (localStorage.getItem('accessToken')) {
        return localStorage.getItem('accessToken');
    }
    
    return null;
}

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.common = { 'X-Requested-With': 'XMLHttpRequest' };

axios.interceptors.request.use((config) => {
    const accessToken = getToken(); 
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
});

axios.interceptors.response.use((res: any) => res, (err: any) => {
    if (err.message.indexOf(401) !== -1) {
        localStorage.removeItem('accessToken');
    } else if (err.message.indexOf(403) !== -1) {
        window.location.href = '/';
    }
    return Promise.reject(err);
});

export interface ApiMeta {
    current_page: number,
    from: number,
    last_page: number,
    path: string,
    per_page: number,
    to: number,
    total: number,
}

export interface ApiSeo {
    seoTitle: string,
    seoDescription: string,
    seoUrl: string,
    seoImageId?: number,
    seoImage?: ApiImage,
    seoNoIndex: boolean,
}

export interface ApiPaginatedResponse<T> {
    data: T,
    meta: ApiMeta,
    links: {
        first: string,
        last: string,
        next?: string,
        prev?: string,
    },
}

export const request = axios;

const api = {
    getProfile: auth.getProfile,
    login: auth.login,
    logout: auth.logout,
    
    deleteDocument: document.delete,
    getDocument: document.get,
    listDocuments: document.list,
    uploadDocument: document.upload,

    deleteHospital: hospital.delete,
    getHospital: hospital.get,
    listHospitals: hospital.list,
    putHospital: hospital.put,
    importHospitals: hospital.uploadImport,

    deleteIndicator: indicator.delete,
    getIndicator: indicator.get,
    listIndicators: indicator.list,
    putIndicator: indicator.put,
    importIndicators: indicator.uploadImport,

    deletePage: page.delete,
    getPage: page.get,
    listPages: page.list,
    putPage: page.put,

    deleteImage: image.delete,
    getImage: image.get,
    listImages: image.list,
    uploadImage: image.upload,
    listImageFolders: image.listFolders,
    putImageFolder: image.putFolder,

    suggestTag: tag.suggest,

    deleteUser: user.delete,
    getUser: user.get,
    listUsers: user.list,
    putUser: user.put,

    front: {
        getPageBySlug: page.getBySlug,
        listNavigation: page.listNavigation,
        getHospital: hospital.getFront,
        getHospitals: hospital.getCompare,
        getIndicators: hospital.getIndicators,
    },
};

export default api;
