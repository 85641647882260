export const fitImage = (src: string, width: number, height?: number): string => {
    if (width > 1920) {
        width = 1920;
        if (height) {
            height = height * (1920 / width);
        }
    }

    let dimensions = `${width}${height ? `x${height}` : ''}`;
    return src.replace('images/', `images/${dimensions}/`);
}
