import { applyMiddleware, createStore, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import auth, { AuthState } from './reducers/auth';

export interface AppState {
    auth: AuthState,
}

export default createStore(combineReducers({
    auth,
}), applyMiddleware(thunkMiddleware));
