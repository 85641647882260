import { AxiosPromise } from 'axios';
import { request } from './index';

export interface ApiTagObject {
    id: number,
    name: string,
    slug: string,
}

const rest = {
    suggest: (query: string, type: string): AxiosPromise => {
        return request(`tags/suggest?q=${query}&type=${type}`);
    },
}

export default rest;
