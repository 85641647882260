import { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse, request } from './';

export type IndicatorType = 'Aantal' | 'Percentage' | 'Boolean';

export interface ApiIndicator {
    id: number,
    parentId: number,
    code: string,
    name: string,
    description: string,
    type: IndicatorType,
    position: number,
    years: number[],
    value?: number,
    average?: number,
    highest?: number,
    lowest?: number,
    isActive: boolean,
}

const rest = {
    delete: (indicatorId: number | string): AxiosPromise => {
        return request.delete(`admin/indicators/${indicatorId}`);
    },
    get: (indicatorId: number): AxiosPromise<ApiIndicator> => {
        return request.get(`admin/indicators/${indicatorId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiIndicator[]>> => {
        return request.get(`indicators?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (indicator: Partial<ApiIndicator>): AxiosPromise<ApiIndicator> => {
        return request.post(`admin/indicators${indicator.id ? `/${indicator.id}` : ''}`, indicator);
    },
    uploadImport: (file: File, year: number): Promise<any> => {
        const data = new FormData();
        data.append('file', file);
        data.append('year', `${year}`);
        return request.post('admin/indicators/upload-import', data);
    },
}

export default rest;
