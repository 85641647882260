import Iframe from './Iframe';
import Image from './Image';
import ImageText from './ImageText';
import Search from './Search';
import Text from './Text';

export const BLOCKS: any = {
    Iframe,
    Image,
    Imagetext: ImageText,
    Search,
    Text,
};

export const blockComponents: any = {
    iframe: Iframe.Block,
    image: Image.Block,
    imagetext: ImageText.Block,
    search: Search.Block,
    text: Text.Block,
};
