import React, { FC, useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { NotificationCenter } from './RbKit';
import api from './api';
import { hasRole, useProfile } from './lib/auth';
import TopBar from './modules/TopBar';
import Drawer from './modules/Drawer';
import Loader from './RbKit/elements/Loader';
import HospitalListView from './views/admin/HospitalView';
import HospitalEditView from './views/admin/HospitalView/Edit';
import IndicatorListView from './views/admin/IndicatorView';
import IndicatorEditView from './views/admin/IndicatorView/Edit';
import UserListView from './views/admin/UserView';
import UserEditView from './views/admin/UserView/Edit';
import PageListView from './views/admin/PageView';
import PageEditView from './views/admin/PageView/Edit';
import LoginView from './views/admin/auth/LoginView';
import DocumentListView from './views/admin/DocumentView';
import ImageListView from './views/admin/ImageView';
import ImageViewModal from './views/admin/ImageView/Modal';
import styles from './styles.module.scss';
import FrontApp from './FrontApp';

const App: FC = (): JSX.Element => {
    const [ isFront, setIsFront ] = useState<boolean>(true);
    const [ isLoading, setIsloading ] = useState<boolean>(true);
    const [ routes, setRoutes ] = useState<JSX.Element[]>([]);
    const profile = useProfile();

    useEffect(() => {
        const f = !window.location.host.includes(process.env.NODE_ENV === 'development' ? '3000' : 'cms');
        setIsFront(f);
        if (f) {
            setIsloading(false);
        } else {
            api.getProfile().catch(() => {}).finally(() => setIsloading(false));
        }
    }, []);

    useEffect(() => {
        if (!profile || isFront) {
            return;
        }

        const rr = [];

        rr.push(<Route key="route-1" component={DocumentListView} exact path="/media/documents" />);
        rr.push(<Route key="route-2" component={ImageListView} exact path="/media/images" />);

        if (hasRole(profile, 'Administrator')) {
            rr.push(<Redirect key="route-a1" exact from="/" to="/admin/users" />);

            rr.push(<Route key="route-a5" component={HospitalListView} exact path="/admin/hospitals" />);
            rr.push(<Route key="route-a6" component={HospitalEditView} exact path="/admin/hospitals/create" />);
            rr.push(<Route key="route-a7" component={HospitalEditView} exact path="/admin/hospitals/:id/edit" />);

            rr.push(<Route key="route-a8" component={IndicatorListView} exact path="/admin/indicators" />);
            rr.push(<Route key="route-a9" component={IndicatorEditView} exact path="/admin/indicators/create" />);
            rr.push(<Route key="route-a10" component={IndicatorEditView} exact path="/admin/indicators/:id/edit" />);

            rr.push(<Route key="route-a11" component={PageListView} exact path="/pages" />);
            rr.push(<Route key="route-a12" component={PageEditView} exact path="/pages/create" />);
            rr.push(<Route key="route-a13" component={PageEditView} exact path="/pages/:id/edit" />);

            rr.push(<Route key="route-a2" component={UserListView} exact path="/admin/users" />);
            rr.push(<Route key="route-a3" component={UserEditView} exact path="/admin/users/create" />);
            rr.push(<Route key="route-a4" component={UserEditView} exact path="/admin/users/:id/edit" />);
        }

        setRoutes(rr);
    }, [profile, isFront]);

    return isFront ? (
        <FrontApp />
    ) : (
        <Router>
            <TopBar />
            {isLoading ? (
                <Loader />
            ) : profile ? (<>
                <Drawer />
                <main className={styles.main}>
                    <div>
                    <Switch>
                        {routes}
                    </Switch>
                    </div>
                </main>
                <ImageViewModal open={false} />
            </>) : (
                <Switch>
                    <Route component={LoginView} path="*" />
                </Switch>
            )}
            <NotificationCenter />
        </Router>
    );
}

export default App;
