import { faChevronRight, faFolder, faPlus, faUpload } from '@fortawesome/pro-light-svg-icons';
import React, { FC, RefObject, useState } from 'react';
import api, { ApiMeta } from '../../../api';
import { ApiImage, ApiImageFolder } from '../../../api/image';
import { Button, Form, Modal, PageHeader, Progress, Segment, Table, toast, triggerModalClose } from '../../../RbKit';
import ImageThumb from '../../../components/ImageThumb';
import styles from './styles.module.scss';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface UploadButtonProps {
    onUpload: (e: any) => void,
}

interface ImageViewProps {
    isModal?: boolean,
    onImageSelect?: (image: ApiImage) => void,
}

export const UploadButton:FC<UploadButtonProps> = ({ onUpload }) => {
    const fileInputRef: RefObject<HTMLInputElement> = React.createRef();

    return (<>
        <Button
            icon={faUpload}
            onClick={() => fileInputRef.current?.click()}
            primary
        />
        <input
            multiple
            onChange={onUpload}
            ref={fileInputRef}
            type="file"
            style={{ display: 'none' }}
            accept="image/*"
        />
    </>);
}

const ImageView: FC<ImageViewProps> = ({ isModal, onImageSelect }) => {
    const [ folders, setFolders ] = useState<ApiImageFolder[]>();
    const [ folder, setFolder ] = useState<ApiImageFolder>();
    const [ folderName, setFolderName ] = useState<string>('');
    const [ images, setImages ] = useState<ApiImage[]>([]);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ remQuery, setQuery ] = useState<string>('');
    const [ isUploading, setIsUploading ] = useState<boolean>(false);
    const [ uploadProgress, setUploadProgress ] = useState<number>(0);
    const [ totalUploadProgress, setTotalUploadProgress ] = useState<number>(0);

    const handleDelete = (imageId: number): void => {
        api.deleteImage(imageId).then(() => {
            toast('Image deleted succesfully');
            setImages([...images.filter(o => o.id !== imageId)]);
        });
    }

    const fetchFolders = useCallback((): void => {
        api.listImageFolders(folder?.id).then(({ data }) => {
            setFolders(data);
        });
    }, [folder]);
    useEffect(fetchFolders, [fetchFolders]);

    const handleSearch = (query?: string, page?: number): void => {
        setIsLoading(true);
        if (query) {
            setQuery(query);
        }

        api.listImages({ query: query || remQuery, page: page || 1, folderId: folder?.id }).then(({ data }) => {
            setIsLoading(false);
            setImages((page || 1) === 1 ? data.data : [...images, ...data.data]);
            setMeta(data.meta);
        });
    }
    useEffect(() => {
        if (folder || !isModal) {
            handleSearch()
        }
    }, [folder]); // eslint-disable-line

    const handleFileUpload = (e: any): void => {
        setIsUploading(true);
        uploadFile(e.target.files, 0, e.target.files.length);
    }

    const handleSelect = (image: ApiImage) => {
        if (onImageSelect) {
            onImageSelect(image);
        }
    }

    const uploadFile = (files: FileList, index: number, max: number): void => {
        setTotalUploadProgress(index / max);
        setUploadProgress(0);

        if (index >= max) {
            setIsUploading(false);
            return;
        }

        api.uploadImage(files[index], folder?.id, (progressEvent: any) => {
            setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }).then(({ data }) => {
            setImages((i) => [data, ...i]);
            uploadFile(files, index + 1, max);
        });
    }

    const addFolder = (): void => {
        if (folderName === '') {
            triggerModalClose();
            return;
        }
        api.putImageFolder({ parentId: folder?.id, name: folderName }).then(({ data }) => {
            setFolder(data);
            setFolderName('');
            toast('Folder added succesfully');
            triggerModalClose();
        });
    }

    const renderBreadCrumb = (): JSX.Element[] => {
        const crumb = [
            <span
                key="crumb-0"
                onClick={() => setFolder(undefined)}
            >
                Images
                <FontAwesomeIcon icon={faChevronRight} />
            </span>,
        ];
        
        if (folder) {
            folder.breadcrumb?.forEach((bc) => {
                crumb.push((
                    <span
                        key={`crumb-${bc.id}`}
                        onClick={() => setFolder(bc)}
                    >
                        <span>{bc.name}</span>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </span>
                ));
            });

            crumb.push((
                <span
                    key={`crumb-${folder.id}`}
                    onClick={() => setFolder(folder)}
                >
                    {folder.name}
                    <FontAwesomeIcon icon={faChevronRight} />
                </span>
            ));
        }

        return crumb;
    }

    return (<>
        {!isModal && (<PageHeader
            breadcrumb={renderBreadCrumb()}
            title="Images"
        >
            <Modal
                header="Add new folder"
                size="small"
                trigger={<Button
                    icon={faPlus}
                    label="Add folder"
                    style={{ marginRight: '1rem' }}
                />}
                footer={<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        link
                        label="cancel"
                        onClick={triggerModalClose}
                    />
                    <Button
                        primary
                        label="Add folder"
                        onClick={addFolder}
                    />
                </div>}
            >
                <Form.Input
                    label="Folder name"
                    required
                    onChange={({ value }: any) => setFolderName(value)}
                    value={folderName}
                />
            </Modal>
            <UploadButton onUpload={(e: any) => handleFileUpload(e)} />
        </PageHeader>)}
        
        {isUploading && <Segment>
            <Progress progress={uploadProgress} style={{ marginBottom: '.5rem' }} />
            <Progress progress={totalUploadProgress * 100} />
        </Segment>}

        {isModal ? (
            <div style={{ marginBottom:  '1rem' }}>
                <Table.Actions autoLoad onSearch={handleSearch} />
            </div>
        ) : (
            <Segment isLoading={isLoading}>
                <Table.Actions onSearch={handleSearch} />
            </Segment>
        )}

        <div className={styles.folders}>
            {folders?.map((f) => (
                <div
                    key={`folder-${f.id}`}
                    className={styles.folder}
                    onClick={() => setFolder(f)}
                >
                    <FontAwesomeIcon icon={faFolder} />
                    {f.name}
                </div>
            ))}
        </div>

        <div className={styles.container}>
            {images.length > 0 && images.map((image, index) => (
                <div
                    key={index}
                    onClick={() => handleSelect(image)}
                    style={onImageSelect ? { cursor: 'pointer' } : {}}
                >
                    <ImageThumb
                        onDelete={onImageSelect ? undefined : handleDelete}
                        image={image}
                        square
                    />
                </div>
            ))}
            <div style={{ clear: 'both' }} />
        </div>
        <div style={{ clear: 'both' }} />

        {meta && <Table.Pagination
            infinite
            meta={meta}
            onChange={(page) => handleSearch(undefined, page)}
        />}

        {isModal && <div className={styles.modalUploadButton}>
            <UploadButton onUpload={(e: any) => handleFileUpload(e)} />
        </div>}
    </>);
}

export default ImageView;
