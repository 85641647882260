import React, { FC } from 'react';
import { ApiIndicator } from '../../../api/indicator';
import { number } from '../../../lib/util';
import styles from './styles.module.scss';

interface AverageProps {
    indicator: ApiIndicator,
    value?: number,
}

const Average: FC<AverageProps> = ({ indicator, value }): JSX.Element | null => {
    return indicator.average ? (
        <div className={styles.base}>
            <div className={styles.tooltip}>
                <p>
                    <span>Laagste</span>
                    <span>{number(indicator.lowest || 0)}</span>
                </p>
                <p>
                    <span>Hoogste</span>
                    <span>{number(indicator.highest || 0)}</span>
                </p>
                <p>
                    <span>Gemiddeld</span>
                    <span>{number(indicator.average || 0)}</span>
                </p>
                <p>
                    <span>Ziekenhuis</span>
                    <span>{number(value || 0)}</span>
                </p>
            </div>
            <div className={styles.bar}>
                <div style={{ width: `${(value || 0) / (indicator.highest || 0) * 100}%` }} />
            </div>
            <div
                className={styles.average}
                style={{ left: `${indicator.average / (indicator.highest || 0) * 100}%` }}
            />
        </div>
    ) : null;
}

export default Average;
