import { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse, request } from './';
import { ApiIndicator } from './indicator';

export interface ApiHospital {
    id: number,
    bhcId: number,
    name: string,
    street: string,
    zipcode: string,
    city: string,
    country: string,
    phone: string,
    url: string,
    slug: string,
    latitude: number,
    longitude: number,
    distance?: number,
    indicators?: ApiIndicator[],
    tags: string[],
}

const rest = {
    delete: (hospitalId: number | string): AxiosPromise => {
        return request.delete(`admin/hospitals/${hospitalId}`);
    },
    getFront: (hospitalId: number, year?: number): AxiosPromise<any> => {
        return request.get(`front/hospitals/${hospitalId}?year=${year}`);
    },
    getCompare: (hospitals: string, year?: number): AxiosPromise<ApiHospital[]> => {
        return request.get(`front/hospitals/compare?hospitals=${hospitals}&year=${year || ''}`);
    },
    getIndicators: (): AxiosPromise<ApiIndicator[]> => {
        return request.get(`front/indicators`);
    },
    get: (hospitalId: number, year?: number): AxiosPromise<ApiHospital> => {
        return request.get(`admin/hospitals/${hospitalId}?year=${year}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiHospital[]>> => {
        return request.get(`hospitals?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (hospital: Partial<ApiHospital>, year: number): AxiosPromise<ApiHospital> => {
        return request.post(`admin/hospitals${hospital.id ? `/${hospital.id}` : ''}`, { ...hospital, year });
    },
    uploadImport: (file: File): Promise<any> => {
        const data = new FormData();
        data.append('file', file);
        return request.post('admin/hospitals/upload-import', data);
    },
}

export default rest;
