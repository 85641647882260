import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { find } from 'lodash';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from '../../../modules/front/Container';
import Map from '../../../modules/front/Map';
import { ApiHospital } from '../../../api/hospital';
import styles from './styles.module.scss';
import { ApiIndicator } from '../../../api/indicator';
import { Dropdown, Form, Modal, Table } from '../../../RbKit';
import { number } from '../../../lib/util';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import Average from '../../../modules/front/Average';

interface HospitalViewProps {
    data: {
        hospital: ApiHospital,
        indicators: ApiIndicator[],
    },
    year: number,
    changeYear: (year: number) => void,
}

const HospitalView: FC<HospitalViewProps> = ({ data, changeYear, year }): JSX.Element => {
    const [ info, setInfo ] = useState<string | boolean>(false);
    
    return (<>
        <Helmet>
            <title>{data.hospital.name}</title>
            <meta name="robots" content="index,follow" />
            <meta name="googlebot" content="index,follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
            <meta name="bingbot" content="index,follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
            <meta property="og:locale" content="nl_NL" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={data.hospital.name} />
            <meta property="og:url" content={data.hospital.slug} />
            <meta property="og:site_name" content="Monitor Borstkanker" />
        </Helmet>
        <Container small>
            <h1>{data.hospital.name}</h1>
            <div className={styles.location}>
                <div className={styles.address}>
                    <b>Adres &amp; contact</b><br />
                    {data.hospital.street}<br />
                    {data.hospital.zipcode} {data.hospital.city}<br />
                    <b>T:</b> {data.hospital.phone}<br />
                    <b>W:</b> <a href={data.hospital.url} target="_blank" rel="noopener noreferrer">{data.hospital.url}</a><br /><br />

                    <Link
                        className="button"
                        to={`/zoeken?compare=${data.hospital.id}`}
                    >
                        <FontAwesomeIcon icon={faChevronRight} />
                        Vergelijken
                    </Link>
                    <a
                        href="https://maps.google.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginLeft: 32, fontSize: 16 }}
                    >
                        Route
                    </a>
                </div>
                <div className={styles.map}>
                    <Map coords={{ lat: data.hospital.latitude, lng: data.hospital.longitude }} />
                </div>
            </div>

            <div className={styles.indicators}>
                {data.indicators.filter((o, i) => i === 0).map((parent) => {
                    const lvl2 = data.indicators.filter((o) => !o.parentId);
                    const p = find(data.hospital.indicators, { id: parent.id });

                    return (
                        <div
                            key={parent.id}
                        >
                            <h3 className={styles.indicator}>
                                Indicatoren
                                <div style={{ maxWidth: 140, zIndex: 103, position: 'relative' }}>
                                    <Form.Dropdown
                                        label="Verslagjaar"
                                        options={[{
                                            text: '2020',
                                            value: 2020,
                                        }, {
                                            text: '2021',
                                            value: 2021,
                                        }, {
                                            text: '2022',
                                            value: 2022,
                                        }, {
                                            text: '2023',
                                            value: 2023,
                                        }]}
                                        onChange={({ value }) => changeYear(value)}
                                        value={year}
                                    />
                                </div>
                            </h3>
                            {lvl2.length > 0 ? (<>
                                {/* <h3 className={styles.indicator}>
                                    {parent.name}
                                    {parent.description && (
                                        <FontAwesomeIcon
                                            icon={faInfoCircle}
                                            className={styles.icon}
                                            onClick={() => setInfo(parent.description)}
                                            style={{ position: 'relative', marginLeft: '.5rem', top: -2 }}
                                        />
                                    )}
                                </h3> */}
                                {lvl2.map((o) => {
                                    const lvl3 = data.indicators.filter((i) => i.parentId === o.id);
                                    const value = find(data.hospital.indicators, { id: o.id });

                                    return (
                                        <Table key={o.id} responsive={false}>
                                            <tbody>
                                                <Table.Row>
                                                    <Table.Cell
                                                        collapsing
                                                        icon={o.description ? <FontAwesomeIcon
                                                            icon={faInfoCircle}
                                                            className={styles.icon}
                                                            onClick={() => setInfo(o.description)}
                                                        /> : undefined}
                                                    />
                                                    <Table.Cell>
                                                        <b>{o.name}</b>
                                                    </Table.Cell>
                                                    <Table.Cell collapsing align="right">
                                                        {value?.type === 'Boolean'
                                                            ? (value?.value === 1 ? 'Ja' : 'Nee')
                                                            : number(value?.value)
                                                        }
                                                        {value?.type === 'Percentage' && '%'}
                                                    </Table.Cell>
                                                    <Table.Cell collapsing align="right" className={styles.bar}>
                                                        {o.type !== 'Boolean' && <Average
                                                            indicator={o}
                                                            value={value?.value}
                                                        />}
                                                    </Table.Cell>
                                                </Table.Row>
                                                {lvl3.map((indicator) => {
                                                    const v = find(data.hospital.indicators, { id: indicator.id });
                                                    return (
                                                        <Table.Row key={indicator.id}>
                                                            <Table.Cell
                                                                collapsing
                                                                icon={indicator.description ? <FontAwesomeIcon
                                                                    icon={faInfoCircle}
                                                                    className={styles.icon}
                                                                    onClick={() => setInfo(indicator.description)}
                                                                /> : undefined}
                                                            />
                                                            <Table.Cell>{indicator.name}</Table.Cell>
                                                            <Table.Cell collapsing align="right">
                                                                {v?.type === 'Boolean'
                                                                    ? (v?.value === 1 ? 'Ja' : 'Nee')
                                                                    : number(v?.value)
                                                                }
                                                                {v?.type === 'Percentage' && '%'}
                                                            </Table.Cell>
                                                            <Table.Cell collapsing align="right" className={styles.bar}>
                                                                {indicator.type !== 'Boolean' && <Average
                                                                    indicator={indicator}
                                                                    value={v?.value}
                                                                />}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    );
                                })}
                            </>) : (
                                <Table key={parent.id} responsive={false}>
                                    <tbody>
                                        <Table.Row>
                                            <Table.Cell
                                                collapsing
                                                icon={parent.description ? <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                    className={styles.icon}
                                                    onClick={() => setInfo(parent.description)}
                                                /> : undefined}
                                            />
                                            <Table.Cell>
                                                <b>{parent.name}</b>
                                            </Table.Cell>
                                            <Table.Cell collapsing align="right">
                                                {p?.type === 'Boolean'
                                                    ? (p?.value === 1 ? 'Ja' : 'Nee')
                                                    : number(p?.value)
                                                }
                                                {p?.type === 'Percentage' && '%'}
                                            </Table.Cell>
                                            <Table.Cell collapsing align="right" className={styles.bar}>
                                                {parent?.type !== 'Boolean' && <Average
                                                    indicator={parent}
                                                    value={p?.value}
                                                />}
                                            </Table.Cell>
                                        </Table.Row>
                                    </tbody>
                                </Table>
                            )}
                        </div>
                    );
                })}
            </div>
            <Link to="/zoeken">
                Terug naar zoeken
            </Link>{' | '}
            <Link to="/goede-informatie-helpt">
                Uitgebreide uitleg over indicatoren
            </Link>
        </Container>
        <Modal
            open={info !== false}
            size="medium"
            header="Informatie"
            onClose={() => setInfo(false)}
            footer={(
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button
                        className="button"
                        onClick={() => setInfo(false)}
                    >Sluiten</button>
                </div>
            )}
        >
            <p style={{ margin: 0 }}>
                {info}
            </p>
        </Modal>
    </>);
}

export default HospitalView;
