import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize, cloneDeep, find } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { BlockData, Button, Modal } from '../../RbKit';
import SortableList, { reorder } from '../../components/SortableList';
import BlockContainer from './BlockContainer';
import { BLOCKS, blockComponents } from './Blocks';
import Sidebar from './Sidebar';
import styles from './styles.module.scss';
import frontStyles from '../../styles.module.scss';
const AVAILABLE_BLOCKS = process.env.REACT_APP_AVAILABLE_BLOCKS?.split(',');

interface ContentEditorProps {
    flex?: boolean,
    addLabel?: string
    availableBlocks?: string[],
    blocks?: any[],
    inner?: boolean,
    onUpdate: (items: any[]) => void,
}

const ContentEditor: FC<ContentEditorProps> = ({ flex, blocks, inner, addLabel, availableBlocks, onUpdate }): JSX.Element => {
    const [ confirm, setConfirm ] = useState<number | boolean>(false);
    const [ editMode, setEditMode ] = useState<boolean>(false);
    const [ items, setItems ] = useState<any[]>(blocks || []);
    const [ showAddModal, setShowAddModal ] = useState<number | boolean>(false);

    useEffect(() => setItems(blocks || []), [blocks]);

    const onDragEnd = (result: any): void => {
        if (result.destination) {
            const newItems = reorder(items, result.source.index, result.destination.index);
            setItems(newItems);
            onUpdate(newItems);
        }
    }

    const handleBlockSelect = (index: number) => {
        const ab = (availableBlocks || AVAILABLE_BLOCKS);

        if (ab && ab.length === 1) {
            handleBlockAdd(ab[0], index);
        } else {
            setShowAddModal(index);
        }
    }

    const handleBlockDuplicate = (item: BlockData, index: number): void => {
        const newItems = [...items];
        const newItem = { ...cloneDeep(item) };
        newItems.splice(index, 0, {
            ...newItem,
            id: `${item.block}-${Math.random().toString(36).substring(2,7)}`
        });
        setItems(newItems);
        onUpdate(newItems);
    }

    const handleBlockAdd = (type: string, index?: number): void => {
        const newItems = [...items];
        const newBlock = {
            id: `${type}-${Math.random().toString(36).substring(2,7)}`,
            ...BLOCKS[type].getData()
        };
        
        newItems.splice(index || (items.length - 1), 0, newBlock);
        setItems(newItems);
        
        setShowAddModal(false);
        onUpdate(newItems);
    }

    const handleBlockEdit = (id: string): void => {
        const block = find([...items], { id });
        const newFields: any[] = [];

        BLOCKS[capitalize(block.block)].getData().fields.forEach((field: any) => {
            const blockField = find(block.fields, { id: field.id });
            newFields.push(blockField || field);
        });

        block.fields = newFields;

        dispatchEvent(new CustomEvent('set-editable-block', { detail: { block } }));
        setEditMode(true);
    }

    const handleBlockDelete = (index: number): void => {
        const newItems = [...items.filter((o, i) => i !== index)];
        setItems(newItems);
        setConfirm(false);
        onUpdate(newItems);
    }

    const handleBlockChange = (index: number, newData: BlockData): void => {
        const newItems = [ ...items ];
        newItems[index] = newData;
        setItems(newItems);
        onUpdate(newItems);
    }

    return (<>
        {!inner && (
            <Sidebar
                onClose={() => {
                    setEditMode(false);
                    onUpdate(items);
                }}
                open={editMode}
            />
        )}
        <div className={`${styles.container} ${frontStyles.mainFront}`}>
            <SortableList
                flex={flex}
                customHandle
                items={items}
                onUpdate={onDragEnd}
                renderListItem={(item: any, index: number, handle: any) => {
                    const Elem = blockComponents[item.block];

                    return <BlockContainer
                        handle={handle}
                        item={item}
                        onDelete={() => setConfirm(index)}
                        onDuplicate={() => handleBlockDuplicate(item, index + 1)}
                        onEdit={item.sidebar !== false && item.fields.length > 0 ? () => handleBlockEdit(item.id) : undefined}
                        onPlus={() => handleBlockSelect(index + 1)}
                    >
                        <Elem
                            data={item}
                            onBlockChange={(newData: any) => handleBlockChange(index, newData)}
                        />
                    </BlockContainer>
                }}
            />
            <div className={styles.button}>
                <Button
                    label={addLabel || 'Blok toevoegen'}
                    onClick={() => handleBlockSelect(items.length)}
                />
            </div>
        </div>
        <Modal
            header="Voeg nieuw blok toe"
            onClose={() => setShowAddModal(false)}
            open={showAddModal !== false}
            size="medium"
        >
            <div className={styles.modalBlocks}>
                {(availableBlocks || AVAILABLE_BLOCKS)?.map((block) => (
                    <div
                        className={styles.modalBlock}
                        key={`add-block-${block}`}
                        onClick={() => handleBlockAdd(block, showAddModal as number)}
                    >
                        <div>
                            {BLOCKS[block] && <FontAwesomeIcon icon={BLOCKS[block].icon} />}
                            {block}
                        </div>
                    </div>
                ))}
            </div>
        </Modal>
        <Modal
            footer={(
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        label="Annuleren"
                        link
                        onClick={() => setConfirm(false)}
                    />
                    <Button
                        label="Ok"
                        primary
                        onClick={() => handleBlockDelete(confirm as number)}
                    />
                </div>
            )}
            header="Are you sure?"
            onClose={() => setConfirm(false)}
            open={confirm !== false}
            size="small"
        >
            Weet je zeker dat je dit blok wilt verwijderen? Je dient nog wel op Oplssan te klikken om de wijzigingen door te voeren.
        </Modal>
    </>);
}

export default ContentEditor;
