import { faPlus, faPencil, faTrashAlt, faUpload } from '@fortawesome/pro-light-svg-icons';
import React, { createRef, FC, RefObject, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Confirm, Menu, Message, PageHeader, Segment, Table, toast, useTableSort } from '../../../RbKit';
import api, { ApiMeta } from '../../../api';
import { ApiHospital } from '../../../api/hospital';

const HospitalListView: FC = (): JSX.Element => {
    const fileInputRef: RefObject<HTMLInputElement> = createRef();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ orderBy, order, setSorting ] = useTableSort('firstName');
    const [ hospitals, setHospitals ] = useState<ApiHospital[]>([]);
    const [ errors, setErrors ] = useState<any>();

    const fetch = useCallback((query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listHospitals({ query, page, orderBy, order }).then(({ data }) => {
            setMeta(data.meta);
            setHospitals(data.data);
            setIsLoading(false);
        });
    }, [orderBy, order]);
    useEffect(() => fetch(), [orderBy, order, fetch]);

    const deleteHospital = (hospitalId: number | string): void => {
        api.deleteHospital(hospitalId).then(() => {
            fetch();
            toast('Ziekenhuis succesvol verwijderd');
        });
    }

    const handleFileUpload = (e: any): void => {
        setErrors(undefined);

        api.importHospitals(e.target.files[0]).then((res) => {
            toast('Import succesvol geüpload, deze zal op de achtergrond draaien.');
        }).catch(({ response }) => {
            if (response.data.errors.file) {
                toast('Ongeldig format, upload CSV of Excel', 'error');
            } else {
                toast('Fouten gevonden in bestand', 'error');
                setErrors(response.data.errors);
            }
        });
    }

    return (<>
        <PageHeader
            title="Ziekenhuizen"
            breadcrumb={{'/admin/hospitals': 'Overzicht'}}
        >
            <input
                accept=".csv, .xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleFileUpload}
                ref={fileInputRef}
                type="file"
                style={{ display: 'none' }}
            />
            <Button
                icon={faUpload}
                label="Importeren"
                onClick={() => fileInputRef.current?.click()}
            />
            <Button
                href="/admin/hospitals/create"
                icon={faPlus}
                padding="compact"
                primary
            />
        </PageHeader>

        {errors ? Object.keys(errors).map((line: any, index) => (
            <Message
                key={`err-${index}`}
                error
                title={`Foutmelding op regel ${line}`}
            >
                <ul>
                    {errors[line].map((o: string, i: number) => <li key={`err-${index}-${i}`}>{o}</li>)}
                </ul>
            </Message>
        )) : (<>
            <Segment isLoading={isLoading}>
                <Table.Actions
                    onSearch={fetch}
                />
                <Table fluid>
                    <thead>
                        <Table.Row>
                            <Table.HeaderCell
                                collapsing
                                sorted={[orderBy, 'bhcId']}
                                order={order}
                                onSort={setSorting}
                            >
                                BHC ID
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={[orderBy, 'name']}
                                order={order}
                                onSort={setSorting}
                            >
                                Naam
                            </Table.HeaderCell>
                            <Table.HeaderCell collapsing>
                                Adres
                            </Table.HeaderCell>
                            <Table.HeaderCell collapsing />
                        </Table.Row>
                    </thead>
                    <tbody>
                        {hospitals.length > 0 ? hospitals.map((hospital) => (
                            <Table.Row key={`row-${hospital.id}`}>
                                <Table.Cell name="BHC ID" collapsing>
                                    {hospital.bhcId}
                                </Table.Cell>
                                <Table.Cell primary name="Naam">
                                    <Link to={`/admin/hospitals/${hospital.id}/edit`}>
                                        {hospital.name}
                                    </Link>
                                </Table.Cell>
                                <Table.Cell collapsing name="Adres">
                                    <small style={{ fontSize: '.75rem', lineHeight: 1 }}>
                                        {hospital.street}<br />
                                        {hospital.zipcode} {hospital.city}
                                    </small>
                                </Table.Cell>
                                <Table.Cell collapsing actions>
                                    <Menu dropdown>
                                        <Menu.Item
                                            icon={faPencil}
                                            content="Wijzigen"
                                            href={`/admin/hospitals/${hospital.id}/edit`}
                                        />
                                        <Menu.Divider />
                                        <Confirm
                                            content="Weet je zeker dat je dit ziekenhuis wilt verwijderen? Gekoppelde informatie zal ook worden verwijderd."
                                            onConfirm={() => deleteHospital(hospital.id)}
                                            trigger={<Menu.Item
                                                icon={faTrashAlt}
                                                content="Verwijderen"
                                            />}
                                        />
                                    </Menu>
                                </Table.Cell>
                            </Table.Row>
                        )) : (
                            <Table.Row noResults />
                        )}
                    </tbody>
                </Table>
            </Segment>
            {meta && <Table.Pagination
                meta={meta}
                onChange={(page: number) => fetch('', page)}
            />}
        </>)}
    </>);
}

export default HospitalListView;
