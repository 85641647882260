import React, { FC } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga4';
import TopBar from './modules/front/TopBar';
import Footer from './modules/front/Footer';
import CompareView from './views/front/CompareView';
import PageView from './views/front/PageView';
import SearchView from './views/front/SearchView';
import styles from './styles.module.scss';
import './default.scss';
ReactGA.initialize('G-9SKZWVJDMJ');

const FrontApp: FC = (): JSX.Element => {
    return (
        <Router>
            <TopBar />
            <div
                className={styles.mainFront}
                id="main"
            >
                <Switch>
                    <Route path="/vergelijker" exact component={CompareView} />);
                    <Route path="/zoeken" exact component={SearchView} />);
                    <Route path="*" component={PageView} />);
                </Switch>
                <Footer />
            </div>
        </Router>
    )
}

export default FrontApp;
