import wNumb from 'wnumb';

export const slugify = (str: string): string => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:; &";
    var to   = "aaaaeeeeiiiioooouuuunc--------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

export const number = (
    str: any,
    decimals: number = 0,
    mark: string = ',', 
    thousand: string = '.'
): string => {
    const format = wNumb({
        decimals,
        thousand,
        mark,
    });

    return format.to(decimals === 0 ? parseInt(str) : parseFloat(str));
};
