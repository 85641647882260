import { faChevronRight, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useState } from 'react';
import { BlockProps, Input } from '../../../../RbKit';
import styles from '../../../../views/front/SearchView/styles.module.scss';
import Container from '../../../front/Container';
import { Link, useHistory } from 'react-router-dom';
import { useHandleKeyUp } from '../../../../RbKit/lib/hooks';

const Block: FC<BlockProps> = (): JSX.Element => {
    const [ query, setQuery ] = useState<string>('');
    const history = useHistory();

    useHandleKeyUp(() => {
        if (query !== '') {
            history.push(`/zoeken?query=${query}`);
        }
    }, [13]);

    return (
        <Container small>
            <div className={styles.searchInput}>
                <Input
                    id="search-input"
                    placeholder="Zoeken op ziekenhuisnaam, postcode of locatie"
                    onChange={({ value }: any) => setQuery(value)}
                    value={query}
                    className={styles.input}
                />
                <FontAwesomeIcon
                    icon={faSearch}
                    onClick={() => { history.push(`/zoeken?query=${query}`); }}
                />
            </div>
            <Link
                className="button"
                to="/zoeken"
            >
                <FontAwesomeIcon icon={faChevronRight} />
                Bekijk alle locaties
            </Link>
        </Container>
    );
}

const data = {
    Block,
    icon: faSearch,
    getData: () => ({
        block: 'search',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Ankerlink',
            value: '',
        }],
    })
};

export default data;
