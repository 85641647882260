import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Checkbox, Form, PageHeader, Segment, toast } from '../../../RbKit';
import api, { ApiSeo } from '../../../api';
import { ApiPage } from '../../../api/page';
import ChangeHandler from '../../../components/ChangeHandler';
import ContentEditor from '../../../modules/ContentEditor';
import SeoView from '../../../modules/Seo';

interface PageEditProps extends RouteComponentProps<{ id?: string }> {
}

const PageEditView: FC<PageEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ page, setPage ] = useState<Partial<ApiPage>>({});
    const [ changesMade, setChangesMade ] = useState<boolean>(false);

    const fetch = useCallback(() => {
        setChangesMade(false);
        if (id) {
            setIsLoading(true);
            api.getPage(parseInt(id)).then(({ data }) => {
                setPage(data);
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setChangesMade(true);
        setPage({
            ...page,
            [name]: value,
        });
    }

    const save = (e?: FormEvent): void => {
        if (e) e.preventDefault();
        setIsLoading(true);

        api.putPage(page).then(() => {
            setChangesMade(false);
            setIsLoading(false);
            toast('Pagina opgeslagen');
            history.push('/pages');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Er ging iets mis', 'error')
        });
    }

    return (<>
        <ChangeHandler
            changesMade={changesMade}
            onCancel={() => fetch()}
            onSave={() => save()}
        />
        <PageHeader
            breadcrumb={{
                '/pages': 'Pagina\'s',
                [`/pages/${id ? `${id}/edit` : 'create'}`]: id ? page.name || 'Nieuw' : 'Nieuw',
            }}
            title={`${id ? 'Wijzig' : 'Nieuwe'} pagina`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Segment isLoading={isLoading}>
                <Form.Group>
                    <Checkbox
                        checked={page.showInMenu}
                        label="Toon in menu"
                        onChange={({ checked }: any) => handleInput({ name: 'showInMenu', value: checked })}
                    />
                </Form.Group>
                <Form.Input
                    error={errors.name}
                    label="Titel"
                    name="name"
                    onChange={handleInput}
                    required
                    value={page.name || ''}
                />
            </Segment>
            <SeoView data={page as ApiSeo} handleInput={handleInput} />
            <ContentEditor
                blocks={page.content}
                onUpdate={(content) => {
                    handleInput({ name: 'content', value: content });
                    setChangesMade(true);
                }}
            />
        </Form>
    </>);
}

export default PageEditView;
