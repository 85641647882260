import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Container from '../../../modules/front/Container';

const NotFoundView: FC = (): JSX.Element => {
    return (<>
        <Helmet>
            <title>Pagina niet gevonden</title>
        </Helmet>
        <Container>
            <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: 1040 }}>
                <h1>404 Pagina niet gevonden</h1>
                <p>
                    Helaas, de pagina die u zocht bestaat (niet) meer.<br />
                    <Link to="/">Klik hier</Link> om terug te keren
                </p>
            </div>
        </Container>
    </>);
}

export default NotFoundView;
