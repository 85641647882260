import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import api from '../../../api';
import { ApiPage } from '../../../api/page';
import Content from '../../../modules/front/Content';
import Loader from '../../../RbKit/elements/Loader';
import NotFoundView from '../NotFoundView';
import HospitalView from '../HospitalView';
import { Helmet } from 'react-helmet';

interface PageViewProps extends RouteComponentProps<{ slug?: string }> {
}

const PageView: FC<PageViewProps> = ({ match }): JSX.Element => {
    const { url } = match;
    const [ fetched, setFetched ] = useState<boolean>(false);
    const [ hospitalData, setHospitalData ] = useState<any>();
    const [ page, setPage ] = useState<ApiPage>();
    const [ year, setYear ] = useState<number>(2023);

    useEffect(() => {
        if (/[a-z]+-[0-9]{1,4}$/i.test(url)) {
            const id = url.match(/([0-9]{1,4})$/);
            if (!id) return;
            api.front.getHospital(parseInt(id[0]), year).then(({ data }) => {
                setFetched(true);
                setHospitalData(data);
            }).catch(() => {
                setFetched(true);
            });
            return;
        } else {
            api.front.getPageBySlug(url.substring(1)).then(({ data }) => {
                setHospitalData(undefined);
                setFetched(true);
                setPage(data);
            }).catch(() => {
                setFetched(true);
            });
        }
    }, [url, year]);

    useEffect(() => {
        const main = document.getElementById('main');
        main?.scrollTo({ behavior: 'auto', top: 0 });
    }, [url]);

    if (!fetched) {
        return <Loader />
    }

    return hospitalData
        ? <HospitalView data={hospitalData} changeYear={(y: number) => setYear(y)} year={year} /> 
        : (page
            ? (<>
                <Helmet>
                    <title>{page.seoTitle || page.name}</title>
                    <meta name="robots" content={page.seoNoIndex ? 'noindex,nofollow' : 'index,follow'} />
                    <meta name="googlebot" content={`${page.seoNoIndex ? 'noindex,nofollow' : 'index,follow'}, max-snippet:-1, max-image-preview:large, max-video-preview:-1`} />
                    <meta name="bingbot" content={`${page.seoNoIndex ? 'noindex,nofollow' : 'index,follow'}, max-snippet:-1, max-image-preview:large, max-video-preview:-1`} />
                    <meta property="og:locale" content="nl_NL" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={page.seoTitle || page.name} />
                    <meta property="og:description" content={page.seoDescription || ''} />
                    <meta property="og:url" content={page.slug} />
                    <meta property="og:site_name" content="Monitor Borstkanker" />
                    {page.seoImage && <meta property="og:image" content={page.seoImage.src} />}
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>
                <Content blocks={page.content} settings={{}} />
            </>)
            : <NotFoundView />
        )
}

export default PageView;
