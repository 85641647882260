import { faFacebookSquare, faInstagramSquare, faLinkedin, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import Container from '../Container';
import styles from './styles.module.scss';

const navigation = [[
    ['/steun-ons/lid-worden', 'Lid worden'],
    ['/steun-ons/lid-doneren', 'Doneren'],
    ['/steun-ons/deel-jouw-ervaring', 'B-force'],
    ['/steun-ons/kom-actie', 'Kom in actie'],
], [
    ['/borstkankervereniging/contact/contact-met-ervaringsdeskundigen', 'Stel je vraag'],
    ['https://www.borstkanker.nl/', 'Agenda'],
    ['/steun-ons/help-mee-als-bedrijf', 'Voor bedrijven'],
    ['/borstkankervereniging/contact/lidmaatschap', 'Lidmaatschap'],
], [
    ['/borstkankervereniging/wie-zijn-wij', 'Wie zijn we'],
    ['/borstkankervereniging/contact/contact-bvn', 'Contactgegevens'],
    ['/borstkankervereniging/werken-bij', 'Vacatures'],
    ['/borstkankervereniging/verenigingszaken/disclaimer', 'Disclaimer'],
], [
    ['https://www.facebook.com/borstkanker', faFacebookSquare],
    ['https://www.instagram.com/borstkankernederland', faInstagramSquare],
    ['https://www.linkedin.com/company/1046412/admin/', faLinkedin],
    ['https://twitter.com/borstkanker', faTwitterSquare],
]];

const Footer: FC = (): JSX.Element => {
    return (
        <footer className={styles.footer}>
            <Container className={styles.innerContainer}>
                <div className={styles.col}>
                    <b>Steun ons</b>
                    {navigation[0].map((item: any, i) => (
                        <a
                            key={`nav-0-${i}`}
                            href={`${item[0].includes('http') ? '' : 'https://www.borstkanker.nl'}${item[0]}`}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <FontAwesomeIcon icon={faChevronRight} />
                            {item[1]}
                        </a>
                    ))}
                </div>
                <div className={styles.col}>
                    <b>Handig</b>
                    {navigation[1].map((item: any, i) => (
                        <a
                            key={`nav-1-${i}`}
                            href={`${item[0].includes('http') ? '' : 'https://www.borstkanker.nl'}${item[0]}`}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <FontAwesomeIcon icon={faChevronRight} />
                            {item[1]}
                        </a>
                    ))}
                </div>
                <div className={styles.col}>
                    <b>Over ons</b>
                    {navigation[2].map((item: any, i) => (
                        <a
                            key={`nav-2-${i}`}
                            href={`${item[0].includes('http') ? '' : 'https://www.borstkanker.nl'}${item[0]}`}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <FontAwesomeIcon icon={faChevronRight} />
                            {item[1]}
                        </a>
                    ))}
                </div>
                <div className={`${styles.col} ${styles.social}`}>
                    <b>Volg ons</b>
                    {navigation[3].map((item: any, i) => (
                        <a
                            key={`nav-3-${i}`}
                            href={item[0]}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <FontAwesomeIcon icon={item[1]} />
                        </a>
                    ))}
                </div>
            </Container>
        </footer>
    );
}

export default Footer;
