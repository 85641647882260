import axios, { AxiosPromise } from 'axios';
import { setProfile } from '../reducers/auth';
import store from '../store';

export interface ApiProgress {
    typeId: number,
    type: string,
    percentage: number,
}

export interface ApiProfile {
    id: string,
    first_name: string,
    last_name: string,
    role: string,
    progress: ApiProgress,
}

const rest = {
    getProfile: async (): Promise<ApiProfile> => {
        return axios.get('auth/profile').then(({ data }: { data: ApiProfile }) => {
            store.dispatch(setProfile(data));
            return data;
        });
    },
    login: (emailAddress: string, password?: string): AxiosPromise => {
        return axios.post('auth/login', { emailAddress, password });
    },
    logout: (): void => {
        localStorage.removeItem('accessToken');
        store.dispatch(setProfile());
    },
    register: (emailAddress: string, password: string, firstName: string, lastName: string): AxiosPromise => {
        return axios.post('auth/register', { emailAddress, password, firstName, lastName });
    },
}

export default rest;
