import { find, findIndex, range } from 'lodash';
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Form, Grid, PageHeader, Segment, toast, Table } from '../../../RbKit';
import api from '../../../api';
import { ApiHospital } from '../../../api/hospital';
import ChangeHandler from '../../../components/ChangeHandler';
import { ApiIndicator } from '../../../api/indicator';
import TagInput from './TagInput';

interface HospitalEditProps extends RouteComponentProps<{ id?: string }> {
}

const HospitalEditView: FC<HospitalEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ hospital, setHospital ] = useState<Partial<ApiHospital>>({});
    const [ indicators, setIndicators ] = useState<ApiIndicator[]>([]);
    const [ changesMade, setChangesMade ] = useState<boolean>(false);
    const [ year, setYear ] = useState<number>(new Date().getFullYear() - 1);
    const years = range(2020, new Date().getFullYear());

    const fetch = useCallback(() => {
        setChangesMade(false);
        if (id) {
            setIsLoading(true);
            api.getHospital(parseInt(id), year).then(({ data }) => {
                setHospital(data);
                setIsLoading(false);
            });
        }
    }, [id, year])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        api.listIndicators({ limit: 999 }).then(({ data }) => setIndicators(data.data));
        fetch();
    }, [id, fetch, year]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setChangesMade(true);
        setHospital({
            ...hospital,
            [name]: value,
        });
    }

    const save = (e?: FormEvent): void => {
        if (e) e.preventDefault();
        setIsLoading(true);

        api.putHospital(hospital, year).then(() => {
            setIsLoading(false);
            setChangesMade(false);
            toast('Ziekenhuis succesvol opgeslagen');
            history.push('/admin/hospitals');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Er ging iets mis', 'error')
        });
    }

    const handleIndicator = (indicatorId: number, value: any): void => {
        if (!hospital.indicators) return;
        const index = findIndex(hospital.indicators, { id: indicatorId });
        if (index === -1) return;
        const indi = [ ...hospital.indicators ];
        indi[index].value = value;
        setHospital({
            ...hospital,
            indicators: indi,
        });
        setChangesMade(true);
    }

    return (<>
        <ChangeHandler
            changesMade={changesMade}
            onCancel={() => fetch()}
            onSave={() => save()}
        />
        <PageHeader
            breadcrumb={{
                '/admin/hospitals': 'Ziekenhuis',
                [`/admin/hospitals/${id ? `${id}/edit` : 'create'}`]: id ? hospital.name || 'Nieuw' : 'Nieuw',
            }}
            title={`${id ? 'Wijzig' : 'Nieuw'} ziekenhuis`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Grid.Row>
                <Grid.Column md={6}>
                    <Segment isLoading={isLoading}>
                        <Form.Input
                            error={errors.bhc_id}
                            label="BHC ID"
                            name="bhcId"
                            onChange={handleInput}
                            required
                            value={hospital.bhcId || ''}
                        />
                        <Form.Input
                            error={errors.name}
                            label="Naam"
                            name="name"
                            onChange={handleInput}
                            required
                            value={hospital.name || ''}
                        />
                        <Form.Input
                            error={errors.address}
                            label="Adres"
                            name="street"
                            onChange={handleInput}
                            required
                            value={hospital.street || ''}
                        />
                        <Grid.Row>
                            <Grid.Column md={4}>
                                <Form.Input
                                    error={errors.zipcode}
                                    label="Postcode"
                                    name="zipcode"
                                    onChange={handleInput}
                                    required
                                    value={hospital.zipcode || ''}
                                />
                            </Grid.Column>
                            <Grid.Column md={8}>
                                <Form.Input
                                    error={errors.city}
                                    label="Plaats"
                                    name="city"
                                    onChange={handleInput}
                                    required
                                    value={hospital.city || ''}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column md={6}>
                                <Form.Input
                                    error={errors.phone}
                                    label="Telefoonnummer"
                                    name="phone"
                                    onChange={handleInput}
                                    required
                                    value={hospital.phone || ''}
                                />
                            </Grid.Column>
                            <Grid.Column md={6}>
                                <Form.Input
                                    error={errors.url}
                                    label="Website"
                                    name="url"
                                    onChange={handleInput}
                                    placeholder="https://"
                                    required
                                    value={hospital.url || ''}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Form.Group label="Zoektermen" focus>
                            <TagInput
                                onChange={(tags: string[]) => handleInput({ name: 'tags', value: tags })}
                                tags={hospital.tags}
                                type="hospital"
                            />
                        </Form.Group>
                    </Segment>
                </Grid.Column>
                <Grid.Column md={6}>
                    <div style={{ maxWidth: 160, zIndex: 9999, position: 'relative' }}>
                        <Form.Dropdown
                            label="Jaar"
                            onChange={({ value }) => setYear(value)}
                            options={years.map((y) => ({
                                text: `${y}`,
                                value: y,
                            }))}
                            value={year}
                        />
                    </div>
                    <Segment style={{ paddingTop: 0 }}>
                        <Table fluid>
                            <tbody>
                                {indicators.map((indicator) => {
                                    const indi = find(hospital.indicators || [], { id: indicator.id }) as ApiIndicator;
                                    return (
                                        <Table.Row key={`indi-${indicator.id}`}>
                                            <Table.Cell style={{ fontSize: 14 }}>
                                                {indicator.name}
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                                <div style={{ minWidth: 120 }}>
                                                    <Form.Input
                                                        label="Waarde"
                                                        onChange={({ value }) => handleIndicator(indicator.id, value)}
                                                        type="number"
                                                        value={indi ? indi.value : 0}
                                                    />
                                                </div>
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Form>
    </>);
}

export default HospitalEditView;
