import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Form, PageHeader, Segment, toast } from '../../../RbKit';
import api from '../../../api';
import { ApiUser } from '../../../api/user';
import ChangeHandler from '../../../components/ChangeHandler';

interface UserEditProps extends RouteComponentProps<{ id?: string }> {
}

const UserEditView: FC<UserEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ user, setUser ] = useState<Partial<ApiUser>>({});
    const [ changesMade, setChangesMade ] = useState<boolean>(false);

    const fetch = useCallback(() => {
        setChangesMade(false);
        if (id) {
            setIsLoading(true);
            api.getUser(parseInt(id)).then(({ data }) => {
                setUser(data);
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setChangesMade(true);
        setUser({
            ...user,
            [name]: value,
        });
    }

    const save = (e?: FormEvent): void => {
        if (e) e.preventDefault();
        setIsLoading(true);

        api.putUser(user).then(() => {
            setChangesMade(false);
            setIsLoading(false);
            toast('Gebruiker succesvol opgeslagen');
            history.push('/admin/users');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Er ging iets mis', 'error')
        });
    }

    return (<>
        <ChangeHandler
            changesMade={changesMade}
            onCancel={() => fetch()}
            onSave={() => save()}
        />
        <PageHeader
            breadcrumb={{
                '/admin/users': 'Gebruikers',
                [`/admin/users/${id ? `${id}/edit` : 'create'}`]: id ? user.firstName || 'Nieuw' : 'Nieuw',
            }}
            title={`${id ? 'Wijzig' : 'Nieuwe'} gebruiker`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Segment isLoading={isLoading}>
                <Form.Dropdown
                    label="Rol"
                    name="role"
                    onChange={handleInput}
                    options={[{
                        text: 'Administrator',
                        value: 'Administrator',
                    }]}
                    required
                    value={user.role}
                />
                <Form.Input
                    error={errors.firstName}
                    label="Voornaam"
                    name="firstName"
                    onChange={handleInput}
                    required
                    value={user.firstName || ''}
                />
                <Form.Input
                    error={errors.lastName}
                    label="Achternaam"
                    name="lastName"
                    onChange={handleInput}
                    required
                    value={user.lastName || ''}
                />
                <Form.Input
                    error={errors.email_address}
                    label="E-mailadres"
                    name="emailAddress"
                    onChange={handleInput}
                    required
                    value={user.emailAddress || ''}
                />
            </Segment>
        </Form>
    </>);
}

export default UserEditView;
