import { createAction, handleActions } from 'redux-actions';
import { ApiProfile } from '../api/auth';

export interface AuthState {
    profile?: ApiProfile,
}

export const setProfile = createAction('setProfile');

const actions = handleActions({
    [setProfile.toString()]: (state, action: any) => ({
        ...state,
        profile: action.payload,
    }),
}, {
    profile: undefined,
});

export default actions;
