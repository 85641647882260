import React, { FC, HTMLAttributes } from 'react';
import styles from './styles.module.scss';

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
    className?: string,
    small?: boolean,
}

const Container: FC<ContainerProps> = ({ className, children, small, style }): JSX.Element => {
    return (
        <div className={`${styles.container} ${small && styles.small} ${className}`} style={style}>
            {children}
        </div>
    );
}

export default Container;
