import React, { FC, useEffect, useState } from 'react';
import { blockComponents } from '../../ContentEditor/Blocks';

interface ContentEditorProps {
    blocks?: any[],
    settings: { [key: string]: any },
}

const Content: FC<ContentEditorProps> = ({ blocks }): JSX.Element => {
    const [ items, setItems ] = useState<any[]>(blocks || []);
    useEffect(() => setItems(blocks || []), [blocks]);

    return (
        <div>
            {items.map((item, index) => {
                const Elem = blockComponents[item.block];

                return (
                    <Elem
                        key={index}
                        data={item}
                        front
                    />
                );
            })}
        </div>
    );
}

export default Content;
